
#mm-prv-wdg {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.mm-prv-agree-submit-container {
  text-align: left;
  margin-top: 10px;
}

.privacy-paragraphs .paragraph-title {
  font-size: 11px;
  text-align: left;
  padding: 4px;
  font-family: arial;
  font-weight: bold;
}
.privacy-paragraphs .scroll-warning {
  padding: 0px 0 0 22px;
  margin-bottom: 0px;
  text-align: left;
  font-weight: normal;
  color: black;
  font-family: arial;
  font-size: 11px;
  border-radius: 2px;
}
@-webkit-keyframes blinker {
from {
    opacity: 1.0;
}
to {
    opacity: 0.0;
}
}
@keyframes blinker {
from {
    opacity: 1.0;
}
to {
    opacity: 0.0;
}
}
.blink {
  -webkit-animation: blinker 1s steps(5, start) infinite;
          animation: blinker 1s steps(5, start) infinite;
  text-decoration: blink;
  -webkit-animation-name: blinker;
  -webkit-animation-duration: 0.6s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-direction: alternate;
}

.privacy-scroller {
  text-align: left;
  padding: 2px 10px;
  min-height: 50px;
  font-family: arial;
  font-size: 11px;
}
.scroll-area-force-visible-y-bar .ps__scrollbar-y-rail {
  opacity: 1 !important;
  background-color: #eeeeee !important;
  width: 12px;
}
.scroll-area-force-visible-y-bar .ps__scrollbar-y-rail .ps__scrollbar-y {
    max-width: 6px;
}
.scroll-area {
  overflow-y: scroll;
  overflow: -moz-scrollbars-vertical;
  background: white;
  border: 1px solid silver;
  position: relative;
  margin: auto;
  margin-bottom: 3px !important;
  width: auto;
  min-height: 50px;
  font-family: arial;
  font-size: 11px;
}

.privacy-flag {
  text-align: left;
  font-size: 11px;
}
.privacy-flag td {
    vertical-align: center;
}
.privacy-flag .privacy-flag-checkbox {
    width: 20px;
}
.privacy-flag-chk-big {
  font-size: 40px;
}
.privacy-flag.not-scrolled {
  color: silver;
}
